




































































import { Component } from 'vue-property-decorator';
import ListBase from '@/lib/listbase';
import FormBase from '@/components/helper/form-base.vue';
import Util from '@/lib/util'
import Lawyer from '@/store/entities/lawyers/lawyer';

@Component({
    components: { FormBase },
})
export default class Enabled extends ListBase {
    name: string = 'enabled';
    document: string = null;  
    lawyer: Lawyer = new Lawyer();
    rules = {
        document: [v => !!v || this.L('Campo Requerido')],
    }          

    /***** COMPUTED METHODS *****/
    get loading() {
        return this.$store.state.lawyer.loading;
    }

    get lastImportDate() {
        return Util.abp.setting.get('LAST_LAWYER_IMPORT');
    }

    /***** EVENTS *****/
    async checkDocument() {
        let model = await this.$store.dispatch({
            type: 'lawyer/getByDocument',
            data: {
                document: this.document
            }
        });
        if (model != null)
            this.lawyer = Util.extend(true, {}, model);
        else {
            Util.abp.notify.error(this, 'Agremiado no encontrado!');
            this.lawyer = null;
        }
    }

    /***** FUNCIONES DE INTERFACE *****/
    getInitials(string) {
        return Util.getInitials(string);
    }


    /***** INIT EVENTS *****/
    mounted() {
        this.lawyer = null;
    }

    created() {
    }
    
}
